<script setup>
    import { watch, reactive } from "vue";
    import VueRouter from "vue-router";

    import PwaOffCanvas from 'o365.pwa.vue.components.navbar.OffCanvas.vue';

    const router = VueRouter.useRouter();
    const route = VueRouter.useRoute();

    const data = reactive({});
    
    function getRouteText(path) {
        const route = router.options.routes.filter(e => e.path === path)[0];

        if (route) {
            return route.meta.title;
        }

        return "Back";
    }

    watch(route, () => {
        const state = router.options.history.state;

        if (state.current === "/") {
            data.text = null;
            data.func = null;
        } else if (state.back != null) {
            // there is history, so we just go back
            data.text = getRouteText(state.back);
            data.func = () => router.back();
        } else {
            // there is no history, but we are not at root, go one step up
            const newRoute = ("/" + state.current.split("/").slice(0, -1).join("/")).replace("//", "/");

            data.text = getRouteText(newRoute);
            data.func = () => router.replace(newRoute);
        }
    }, { immediate: true });
</script>

<template>
    <div class="position-relative text-white flex-shrink-0" style="background-color: #4682b4 !important; height: 48px!important;">
        <!-- middle: title -->
        <div class="d-flex justify-content-center align-items-center" style="position: absolute; inset: 0;">
            <div :key="$route.meta.title" style="position: absolute;">
                <span style="font-size: 1.1em; font-weight: 500;">{{ $route.meta.title }}</span>
            </div>
        </div>

        <!-- left: back button -->
        <div class="d-flex align-items-center" style="position: absolute; top: 0; left: 0; bottom: 0;">
            <div :key="data.text" style="position: absolute;">
                <button class="d-flex align-items-center btn btn-link"
                    style="padding-left: 0.75rem; height: 100%; color: white; text-decoration: none; font-size: 0.9em; white-space: nowrap; gap: 0.25rem;"
                    @click="data?.func()"
                    v-if="data.func">
                    <i class="bi bi-chevron-left"></i>
                    <span>{{ data.text }}</span>
                </button>
            </div>
        </div>

        <!-- right: right slot -->
        <div class="d-flex align-items-center gap-2" style="position: absolute; top: 0; right: 0; bottom: 0;">
            <slot name="extraNavActions"></slot>
            <PwaOffCanvas>
                <template #syncActions>
                    <slot name="syncActions"></slot>
                </template>
            </PwaOffCanvas>
        </div>
    </div>
</template>

<style scoped>
    a.no-style {
        text-decoration: none;
    }

    a.no-style:hover, a.no-style:focus, a.no-style:active, a.no-style:visited {
        text-decoration: none;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.3s, scale 0.2s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
        opacity: 0;
        scale: 0.95;
    }
</style>
